<template>
  <div class="card">
    <div class="card-body">
      <div>
        <h5 style="color: blue; margin-bottom: 30px">
          {{ $t("paiement.paiementClasse") }}
        </h5>
        <label>{{ $t("paiement.listeClasses") }}</label>
        <a-select
          id="selectClass"
          show-search
          :placeholder="$t('paiement.listeClasses')"
          option-filter-prop="children"
          :filter-option="filterOption"
          style="width: 200px; margin-left: 15px"
          @change="
            (val) => {
              handleFilterChange('classe', val);
            }
          "
        >
          <a-select-option value="all">
            {{ $t("paiement.tousClasses") }}
          </a-select-option>
          <a-select-option
            v-for="classe in classes"
            :key="classe._id"
            :value="classe._id"
          >
            {{ classe.name }}
          </a-select-option>
        </a-select>
        <label style="margin-right: 15px; margin-left: 90px">{{
          $t("paiement.mois")
        }}</label>
        <a-select
          id="selectMonth"
          :placeholder="$t('paiement.selectionnerMois')"
          style="width: 200px; margin-left: 15px"
          @change="
            (val) => {
              handleFilterChange('month', val);
            }
          "
        >
          <a-select-option value="all">
            {{ $t("paiement.tousMois") }}
          </a-select-option>
          <a-select-option
            v-for="(value, key) in frMonths"
            :key="key"
            :value="key"
          >
            {{ value }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <h5 style="color: blue; margin-top: 30px">
          {{ $t("inscription.inscription") }}
        </h5>
        <a-button type="file-pdf" @click="convert" style="float: right">
          <a-icon type="file-pdf" @click="convert" />
          {{ $t("paiement.imprimerTab") }} </a-button
        ><br /><br />
        <a-table
          @change="tableChanged"
          :loading="tableLoading"
          :pagination="true"
          :data-source="activeData"
          :columns="columns"
        >
          <template
            slot="expandedRowRender"
            slot-scope="record"
            style="margin: 0"
          >
            <div class="d-inline">
              <a-tag :color="record.inscription ? 'green' : 'red'">
                <b v-if="record.inscription">
                  {{ $t("paiement.inscriPaye") }}</b
                >
                <b v-else>{{ $t("paiement.inscriNonPaye") }}</b>
              </a-tag>
            </div>
            <div
              v-for="(month, key, index) in record.months"
              :key="index"
              class="d-inline"
            >
              <a-tag :color="month ? 'green' : 'red'">
                <b v-if="month"
                  >{{ frMonths[parseInt(key.split("-")[1])] }}
                  {{ $t("paiement.paye") }}</b
                >
                <b v-else
                  >{{ frMonths[parseInt(key.split("-")[1])] }}
                  {{ $t("paiement.impaye") }}</b
                >
              </a-tag>
              <div v-if="index % 2 == 1"><br /></div>
            </div>
          </template>
          <template slot="status" slot-scope="text, record">
            <a-tag :color="record.status == 'payed' ? 'green' : 'red'">
              <b v-if="record.status == 'payed'"> {{ $t("paiement.paye") }}</b>
              <b v-else> {{ $t("paiement.impaye") }}</b>
            </a-tag>
          </template>
          <template slot="libelle" slot-scope="text, record">
            <editable-cell
              :text="text"
              @change="onCellChange(record.key, 'libelle', $event)"
            />
          </template>
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="$t('paiement.chercher') + ` ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("inscription.rechercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable */
//ANCHOR class/month filter to this view: DONE
import JsPDF from "jspdf";
import "jspdf-autotable";
import apiClient from "@/services/axios";
import { mapState } from "vuex";
import moment from "moment";

const dateRange = (startDate, endDate) => {
  var start = startDate.split("-");
  var end = endDate.split("-");
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var dates = [];

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? "0" + month : month;
      dates.push([i, displayMonth, "01"].join("-"));
    }
  }
  return dates;
};

const monthly = (start, end) => {
  let monthObj = Object();
  dateRange(start, end).forEach((date) => (monthObj[date] = false));
  return monthObj;
};

const frMonths = {};
const months = moment.months();
for (let i = 0; i < months.length; i++) frMonths[i + 1] = months[i];

const getStatus = (classRoomPayment) => {
  if (!classRoomPayment.inscription) return "impayed";
  else {
    for (const [key, value] of Object.entries(classRoomPayment.months)) {
      if (!value) return "impayed";
    }
    return "payed";
  }
};
export default {
  computed: mapState(["settings"]),
  async created() {
    this.tableLoading = true;
    await apiClient
      .post("/classrooms/payment/filter", {
        query: {
          schoolarYear: this.settings.activeSchoolarYear,
        },
        aggregation: [
          {
            $lookup: {
              from: "students",
              localField: "student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: {
                    $arrayElemAt: [
                      `$student.schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                      0,
                    ],
                  },
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classroom",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "student.parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $project: {
              _id: 1,
              months: 1,
              inscription: 1,
              student: {
                _id: 1,
                firstName: 1,
                lastName: 1,
              },
              parent: {
                father: 1,
                mother: 1,
              },
              classroom: {
                _id: 1,
                name: 1,
                status: 1,
              },
            },
          },
        ],
      })
      .then((res) => {
        const data = res.data.filter((e) => {
          if (e.classroom[0]) return e.classroom[0].status == "active";
          else return true;
        });
        data.map((elem) => {
          try {
            this.rowData.push({
              _id: elem._id,
              key: elem._id,
              months: elem.months,
              status: getStatus(elem),
              inscription: elem.inscription,
              fullName:
                elem.student[0].firstName + " " + elem.student[0].lastName,
              student_id: elem.student[0]._id,
              parent:
                elem.parent[0].father.firstName +
                " " +
                elem.parent[0].father.lastName,
              classroom: elem.classroom[0].name,
              classroom_id: elem.classroom[0]._id,
            });
          } catch {}
        });
        this.activeData = this.rowData;
        this.tableLoading = false;
        this.filtredTable = this.activeData;
      });

    apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
          },
        },
      })
      .then((res) => (this.classes = res.data));
    this.tableLoading = false;
    this.filtredTable = this.activeData;
  },
  data() {
    return {
      filtredTable: [],
      frMonths,
      tableLoading: false,
      activeData: [],
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
      rowData: [],
      data1: null,
      classes: [],
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      columns: [
        {
          title: this.$t("inscription.nom"),
          dataIndex: "fullName",
          key: "fullName",
          scopedSlots: {
            customRender: "fullName",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.fullName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("menu.scolarite"),
          dataIndex: "classroom",
          key: "classroom",
          scopedSlots: {
            customRender: "classroom",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.classroom
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("profil.etat"),
          dataIndex: "status",
          key: "status",
          filters: [
            {
              text: this.$t("paiement.paye"),
              value: "payed",
            },
            {
              text: this.$t("paiement.impaye"),
              value: "impayed",
            },
          ],
          scopedSlots: {
            customRender: "status",
          },
          onFilter: (value, record) => record.status.indexOf(value) === 0,
        },
      ],
    };
  },
  methods: {
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    handleFilterChange(on, value) {
      const other = on == "classe" ? "month" : "classe";
      var newData = this.rowData;
      if (value == "all") this.filters[on] = false;
      else {
        this.filters[on] = true;
        this.filters[`${on}_val`] = value;
        if (on == "classe")
          newData = this.rowData.filter((elem) => elem.classroom_id == value);
        else {
          newData = [];
          this.rowData.forEach((elem) => {
            const month = Object();
            Object.keys(elem.months).forEach((key) => {
              if (parseInt(key.split("-")[1]) == parseInt(value))
                month[key] = elem.months[key];
            });
            newData.push({
              ...elem,
              months: month,
              status: getStatus({
                inscription: elem.inscription,
                months: month,
              }),
            });
          });
        }
      }
      //handle the other filter if exists (again)
      if (this.filters[other]) {
        if (other == "classe") {
          newData = newData.filter(
            (elem) => elem.classroom_id == this.filters[`${other}_val`]
          );
        } else {
          const temp = [...newData];
          newData = [];
          temp.forEach((elem) => {
            const month = Object();
            Object.keys(elem.months).forEach((key) => {
              if (
                parseInt(key.split("-")[1]) ==
                parseInt(this.filters[`${other}_val`])
              )
                month[key] = elem.months[key];
            });
            newData.push({
              ...elem,
              months: month,
              status: getStatus({
                inscription: elem.inscription,
                months: month,
              }),
            });
          });
        }
      }
      this.activeData = newData;
      this.filtredTable = newData;
    },
    convert() {
      this.$gtag.event("Imp tab etatPaiementScolarite", {
        event_category: "Impression PDF",
        event_label: "scolarite - etatPaiement section",
        value: 1,
      });

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      var doc = new JsPDF();
      doc.setFont("Amiri");
      doc.setFontSize(15);
      var today = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      var SelectedMonth = document.getElementById("selectMonth").innerText;
      var SelectedClass = document.getElementById("selectClass").innerText;
      var currentMonth = today.toLocaleDateString("fr-FR", { month: "long" });
      var className = this.selectedClass;
      doc.setFontSize(10);

      doc.text(
        130,
        20,
        "Imprimé le : " + " " + today.toLocaleDateString("fr-FR", options)
      );
      doc.setFontSize(15);
      doc.text(
        70,
        50,
        this.filters.month
          ? `Etat des paiements pour le mois ${SelectedMonth}`
          : `Etat des paiements pour tous les mois`
      );
      doc.setFontSize(15);
      doc.text(
        80,
        60,
        this.filters.classe
          ? "Etat des paiements - " + SelectedClass
          : "Etat des paiements - Scolartié"
      );
      try {
        doc.addImage(imgData, "JPEG", 10, 10, 40, 40);
      } catch (error) {}
      var arr = [];
      const array = [];
      var counter = 1;

      for (let i = 0; i < this.filtredTable.length; i++) {
        let currentIndex = 0;
        let colSpan = this.filters.month ? 0 : 1;

        if (this.filtredTable[i].months) {
          for (var key of Object.keys(this.filtredTable[i].months)) {
            colSpan++;
          }
        }
        if (currentIndex === 0) {
          currentIndex++;
          arr.push({
            rowSpan: colSpan,
            content: counter,
            styles: { valign: "middle" },
          });
          arr.push({
            rowSpan: colSpan,
            content: this.filtredTable[i].fullName,
            styles: { valign: "middle" },
          });
          arr.push({
            rowSpan: colSpan,
            content: this.filtredTable[i].classroom,
            styles: { valign: "middle" },
          });
          counter++;
        }

        if (!this.filters.month) {
          arr.push({ content: "Inscription", styles: { fontStyle: "bold" } });
          this.filtredTable[i].inscription == true
            ? arr.push({
                content: "payé",
                styles: { textColor: [14, 115, 31], fontStyle: "bold" },
              })
            : arr.push({
                content: "impayé",
                styles: { textColor: [209, 23, 45], fontStyle: "bold" },
              });
          array.push(arr);
          arr = [];
        }

        for (var key in this.filtredTable[i].months) {
          let mois = new Date(key);
          arr.push(mois.toLocaleDateString("fr-FR", { month: "long" }));
          this.filtredTable[i].months[key] == true
            ? arr.push({
                content: "payé",
                styles: { textColor: [14, 115, 31], fontStyle: "bold" },
              })
            : arr.push({
                content: "impayé",
                styles: { textColor: [209, 23, 45], fontStyle: "bold" },
              });
          array.push(arr);
          arr = [];
        }
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        head: [["Num", "Nom complet", "Classe", "Frais", "Etat"]],
        body: array,
        margin: { top: 65 },
        didDrawPage: function (data) {
          // Reseting top margin. The change will be reflected only after print the first page.
          data.settings.margin.top = 10;
        },
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Etat_paiements_scolarité.pdf");
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style scoped></style>
